// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyDPjKwzjCmPHnIltwmG9-Db2ST4SSToyDo",
  authDomain: "yuze-westbury.firebaseapp.com",
  projectId: "yuze-westbury",
  storageBucket: "yuze-westbury.appspot.com",
  messagingSenderId: "265195899893",
  appId: "1:265195899893:web:c3818753d2ad48003c2ebc",
  measurementId: "G-CRBRXRB1DL"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyC-DsWeMrcWpnLHcWdSXKuJ2vtJbA5dLGs",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:3c2cbbff655c8547e8dec1",
  measurementId: "G-KLRDX1529N"
};

export { firebaseCompanyConfig, firebaseBackOfficeConfig }
