import React from "react";
import { Helmet } from 'react-helmet';


const MetaData = () => {

  return (
    <Helmet>
    <title>Bengal Express - Darlington | Order Direct and Save</title>
    <meta name="application-name" content="Bengal Express" />
    <meta name="description" content="Order delicious Indian food online with Bengal Express and enjoy exclusive savings. Freshly prepared dishes available for fast delivery in Darlington or collection." />
    <meta name="keywords" content="React Native Web, SEO, Example" />
    <meta property="og:title" content="Bengal Express - Darlington | Order Direct and Save" />
    <meta property="og:description" content="Order delicious Indian food online with Bengal Express and enjoy exclusive savings. Freshly prepared dishes available for fast delivery in Darlington or collection." />
    <meta property="og:url" content="https://bengalexpress.co.uk" />
  </Helmet>
  );
};

export default MetaData;
