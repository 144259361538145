import * as React from 'react';
import { View, StyleSheet, ActivityIndicator, Text, useState, StatusBar } from 'react-native';
import { MainDrawer } from './navigation/MainDrawer';

function Main() {

  return (
    <View style={styles.container}>
      <StatusBar barStyle="light-content" translucent={true} />
      <MainDrawer />
    </View>
  )
}


export default Main;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
});
